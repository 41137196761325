// src/pages/Profile.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import supabase from '../config/supabaseClient';
import './Profile.css';

function Profile() {
  const { session } = useAuth();
  const [userData, setUserData] = useState({
    username: '',
    phone: '',
    country: ''
  });
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState(userData);

  useEffect(() => {
    const fetchUserData = async () => {
      if (session) {
        const { data: userProfile, error } = await supabase
          .from('users')
          .select('username, email, phone, country')
          .eq('id', session.user.id)
          .single();
        
        if (error) {
          console.error('Erreur de récupération des données utilisateur:', error.message);
        } else {
          setUserData(userProfile);
          setFormData(userProfile); // Initialise formData avec les données utilisateur
        }
        setLoading(false);
      }
    };

    fetchUserData();
  }, [session]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSave = async () => {
    const { error } = await supabase
      .from('users')
      .update(formData)
      .eq('id', session.user.id);
    
    if (error) {
      console.error('Erreur de mise à jour des données utilisateur:', error.message);
    } else {
      setUserData(formData);
      setEditing(false);
    }
  };

  const handleEdit = () => {
    setEditing(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-container">
      <h1>Profil de l'utilisateur</h1>
      <div className="profile-info">
        <div className="profile-item">
          <label htmlFor="username">Nom d'utilisateur:</label>
          {editing ? (
            <input
              type="text"
              name="username"
              id="username"
              value={formData.username}
              onChange={handleChange}
            />
          ) : (
            <span>{userData.username}</span>
          )}
        </div>
        <div className="profile-item">
          <label htmlFor="email">Email:</label>
          <span>{userData.email}</span>
        </div>
        <div className="profile-item">
          <label htmlFor="phone">Numéro de téléphone:</label>
          {editing ? (
            <input
              type="tel"
              name="phone"
              id="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          ) : (
            <span>{userData.phone}</span>
          )}
        </div>
        <div className="profile-item">
          <label htmlFor="country">Pays:</label>
          {editing ? (
            <input
              type="text"
              name="country"
              id="country"
              value={formData.country}
              onChange={handleChange}
            />
          ) : (
            <span>{userData.country}</span>
          )}
        </div>
      </div>
      <div className="profile-actions">
        {editing ? (
          <button onClick={handleSave}>Enregistrer</button>
        ) : (
          <button onClick={handleEdit}>Modifier</button>
        )}
      </div>
    </div>
  );
}

export default Profile;
