import React from 'react';

function TermsofService() {
  return (
    <body>
    <div>
  
  <h1>Conditions d'Utilisation</h1>

<p>Date de dernière mise à jour : 28/07/2024</p>

<p>Bienvenue sur notre application de collaboration entre influenceurs et marques ("TetrisNews"). En accédant ou en utilisant l'Application, vous acceptez de vous conformer et d'être lié par les présentes Conditions d'utilisation. Si vous n'acceptez pas ces termes, veuillez ne pas utiliser l'Application.</p>

<h2>1. Description des Services</h2>
<p>L'Application permet aux créateurs de contenu de découvrir et de participer à des campagnes publicitaires proposées par des marques. Elle offre également un service de flux RSS personnalisé pour les utilisateurs, avec des options de promotion pour les marques.</p>

<h2>2. Inscription et Compte</h2>
<p>Pour utiliser certains services de l'Application, les utilisateurs doivent créer un compte. Vous êtes responsable de maintenir la confidentialité de vos informations de connexion et de toutes les activités sur votre compte.</p>

<h3>2.1 Créateurs de Contenu</h3>
<p>Les créateurs de contenu doivent fournir des informations exactes et complètes lors de l'inscription. Ils sont également tenus de respecter les conditions de la plateforme de réseau social sur laquelle ils publient du contenu promotionnel.</p>

<h3>2.2 Marques</h3>
<p>Les marques doivent fournir des informations commerciales exactes et sont responsables du contenu publicitaire qu'elles soumettent à l'Application. Elles doivent également respecter les conditions de l'Application et des réseaux sociaux impliqués.</p>

<h2>3. Utilisation Acceptable</h2>
<p>Vous acceptez d'utiliser l'Application uniquement à des fins légales et conformément aux présentes Conditions d'utilisation. Vous vous engagez à ne pas :</p>
<ul>
    <li>Publier ou partager du contenu diffamatoire, obscène, offensant ou illégal.</li>
    <li>Usurper l'identité de toute personne ou entité, ou falsifier votre affiliation à une personne ou entité.</li>
    <li>Interférer avec le fonctionnement de l'Application ou nuire à l'expérience des autres utilisateurs.</li>
</ul>

<h2>4. Propriété Intellectuelle</h2>
<p>Le contenu et les services de l'Application, y compris les textes, graphiques, logos, et autres matériaux, sont protégés par des droits d'auteur, des marques déposées et d'autres lois sur la propriété intellectuelle. Vous ne pouvez pas copier, modifier, distribuer ou vendre tout contenu de l'Application sans notre autorisation écrite préalable.</p>

<h2>5. Limitation de Responsabilité</h2>
<p>L'Application est fournie "telle quelle" et "selon disponibilité". Nous ne garantissons pas que les services seront ininterrompus ou exempts d'erreurs. Nous ne sommes pas responsables des dommages directs, indirects, accessoires, spéciaux ou consécutifs découlant de votre utilisation de l'Application.</p>

<h2>6. Modifications des Conditions d'Utilisation</h2>
<p>Nous nous réservons le droit de modifier ces Conditions d'utilisation à tout moment. Les modifications prendront effet immédiatement après leur publication sur cette page. Votre utilisation continue de l'Application après la publication des modifications constitue votre acceptation des nouvelles Conditions d'utilisation.</p>

<h2>7. Résiliation</h2>
<p>Nous pouvons suspendre ou résilier votre accès à l'Application à tout moment, sans préavis, pour toute violation de ces Conditions d'utilisation ou pour toute autre raison que nous jugeons appropriée.</p>

<h2>8. Droit Applicable</h2>
<p>Ces Conditions d'utilisation sont régies par les lois de France. Tout litige sera soumis à la compétence exclusive des tribunaux de France.</p>

<h2>9. Contact</h2>
<p>Pour toute question ou préoccupation concernant ces Conditions d'utilisation, veuillez nous contacter à l'adresse suivante : tetrisnews@proton.me</p>

<p>En utilisant l'Application, vous acceptez les termes de ces Conditions d'utilisation.</p>

    </div>
    </body>
  );
}

export default TermsofService;
