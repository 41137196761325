import React, { useState } from 'react';
import './EventbriteApiModal.css'; // Assurez-vous d'importer votre fichier CSS

const EventbriteApiModal = ({ onSubmit, onCancel }) => {
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [cityOptions, setCityOptions] = useState([]);
    const [inputValue, setInputValue] = useState(''); // Pour gérer l'input de la ville

    const handleCityInputChange = async (inputValue) => {
        setInputValue(inputValue); // Mettez à jour la valeur d'entrée pour l'affichage
        if (inputValue.length > 1) {
            try {
                const response = await fetch(`https://geo.api.gouv.fr/communes?nom=${inputValue}&fields=departement&limit=5`);
                const data = await response.json();
                setCityOptions(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des villes :", error);
            }
        } else {
            setCityOptions([]); // Réinitialiser les options si l'input est vide ou a moins de 2 caractères
        }
    };

    const handleCityChange = (city) => {
        setSelectedCity(city.nom);
        setInputValue(city.nom); // Mettre à jour le champ de saisie avec la ville sélectionnée
        setCityOptions([]); // Vider les suggestions après la sélection
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Vérifie que les champs de ville et de catégorie sont sélectionnés avant de soumettre
        if (selectedCity && selectedCategory) {
            onSubmit({
                city: selectedCity,
                category: selectedCategory,
            });
        }
    };

    return (
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="citySearch">Ville:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="citySearch"
                            placeholder="Tapez pour rechercher une ville"
                            value={inputValue}
                            onChange={(e) => handleCityInputChange(e.target.value)}
                        />
                        {/* Affichage des suggestions */}
                        {cityOptions.length > 0 && (
                            <ul className="list-group mt-2">
                                {cityOptions.map((city) => (
                                    <li
                                        key={city.nom}
                                        className="list-group-item list-group-item-action"
                                        onClick={() => handleCityChange(city)} // Met à jour la ville sélectionnée
                                    >
                                        {city.nom} ({city.departement.nom})
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="categorySelect">Catégorie:</label>
                        <select
                            className="form-control"
                            id="categorySelect"
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Sélectionner une catégorie</option>
                            <option value="Music">Musique</option>
                            <option value="Business">Affaires</option>
                            <option value="Food & Drink">Nourriture et Boissons</option>
                            <option value="Community">Communauté</option>
                            <option value="Performing & Visual Arts">Arts de la Scène et Visuels</option>
                            <option value="Film & Media">Film et Médias</option>
                            <option value="Sports & Fitness">Sports et Fitness</option>
                            <option value="Health">Santé</option>
                            <option value="Science & Tech">Science et Technologie</option>
                            <option value="Travel & Outdoor">Voyages et Plein Air</option>
                        </select>
                    </div>

                    <button type="submit" className="btn btn-primary">Confirmer</button>
                    <button type="button" className="btn btn-warning" onClick={onCancel}>Annuler</button>
                </form>
            </div>
        </div>
    );
};

export default EventbriteApiModal;
