import React from 'react';
import './LegalPrivacy.css'; // Importation du fichier CSS

function Legalprivacy() {
  return (
    <body>
    <div>
       <h1>Politique de Confidentialité</h1>

<p>Date de dernière mise à jour : 28/07/2024</p>

<p>Bienvenue sur l'application de collaboration entre influenceurs et marques ("l'Application"). Cette politique de confidentialité décrit comment nous collectons, utilisons et protégeons les informations personnelles et commerciales des utilisateurs de l'Application, y compris les créateurs de contenu et les marques partenaires.</p>

<h2>1. Collecte des Informations</h2>

<h3>1.1 Informations Collectées</h3>
<p>Nous collectons divers types d'informations, y compris :</p>
<ul>
    <li><strong>Informations personnelles :</strong> telles que le nom, l'adresse e-mail, le numéro de téléphone et les informations de compte des réseaux sociaux.</li>
    <li><strong>Informations commerciales :</strong> pour les marques, incluant les informations de paiement et les préférences de campagne publicitaire.</li>
    <li><strong>Contenu des utilisateurs :</strong> les vidéos, images et autres contenus partagés par les créateurs de contenu.</li>
    <li><strong>Données d'utilisation :</strong> telles que l'historique de navigation, les préférences de flux RSS, et les interactions avec les campagnes publicitaires.</li>
</ul>

<h2>2. Utilisation des Informations</h2>

<p>Les informations collectées sont utilisées pour :</p>
<ul>
    <li>Faciliter la collaboration entre les créateurs de contenu et les marques.</li>
    <li>Gérer et personnaliser les campagnes publicitaires.</li>
    <li>Fournir et améliorer les services de l'Application.</li>
    <li>Communiquer avec les utilisateurs concernant les mises à jour et les offres spéciales.</li>
</ul>

<h2>3. Partage des Informations</h2>

<p>Nous pouvons partager vos informations avec :</p>
<ul>
    <li><strong>Les marques :</strong> qui collaborent avec les créateurs de contenu pour les campagnes publicitaires.</li>
    <li><strong>Fournisseurs de services :</strong> qui nous aident à exploiter l'Application.</li>
    <li><strong>Autorités légales :</strong> si nécessaire pour se conformer aux lois en vigueur.</li>
</ul>

<h2>4. Sécurité des Données</h2>

<p>Nous prenons des mesures de sécurité appropriées pour protéger vos informations contre l'accès non autorisé, l'altération, la divulgation ou la destruction.</p>

<h2>5. Vos Droits</h2>

<p>Vous avez le droit de :</p>
<ul>
    <li>Accéder, corriger ou supprimer vos informations personnelles.</li>
    <li>Vous opposer au traitement de vos données à des fins de marketing direct.</li>
    <li>Limiter le traitement de vos données dans certaines circonstances.</li>
</ul>

<h2>6. Modifications de la Politique de Confidentialité</h2>

<p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications prendront effet immédiatement après leur publication sur cette page.</p>

<h2>7. Contact</h2>

<p>Pour toute question ou préoccupation concernant cette politique de confidentialité, veuillez nous contacter à l'adresse suivante : [adresse e-mail de contact].</p>

<p>En utilisant l'Application, vous acceptez les termes de cette politique de confidentialité.</p>

    </div>
    </body>
  );
}

export default Legalprivacy;
