import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import supabase from '../config/supabaseClient';
import EventbriteApiModal from '../components/EventbriteApiModal';
import FlightForm from '../components/FlightForm';
import './Flux.css'; 

// Fonctions utilitaires pour les appels à Supabase
const fetchFlowsData = async () => {
  return await supabase
    .from('flux')
    .select('id, flow_name, language, country, publication_frequency, flow_description, flow_logo, categorie_flux_id, flow_url, modalNumber');
};

const fetchInscriptionsData = async (userId) => {
  return await supabase
    .from('inscription')
    .select('flow_id')
    .eq('user_id', userId);
};

const fetchCategoriesData = async () => {
  return await supabase
    .from('categorie_flux')
    .select('categorie_flux_id, category_name');
};

const fetchCountriesData = async () => {
  return await supabase
    .from('flux')
    .select('country');
};

const insertInscription = async (userId, flowId, url) => {
  return await supabase
    .from('inscription')
    .insert({ user_id: userId, flow_id: flowId, flow_param: url });
};

// Composant Flux
function Flux() {
  const { session } = useAuth();
  const [flows, setFlows] = useState([]);
  const [filteredFlows, setFilteredFlows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formData, setFormData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('All');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedCountry, setSelectedCountry] = useState('All');
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);

  const fetchFlows = useCallback(async () => {
    const { data: flowsData, error: flowsError } = await fetchFlowsData();
    
    if (flowsError) {
      console.error('Erreur de récupération des flux:', flowsError.message);
    } else {
      if (session) {
        const { data: inscriptionsData, error: inscriptionsError } = await fetchInscriptionsData(session.user.id);
        
        if (inscriptionsError) {
          console.error('Erreur de récupération des inscriptions:', inscriptionsError.message);
        } else {
          const registeredFlowIds = new Set(inscriptionsData.map(inscription => inscription.flow_id));
          const updatedFlows = flowsData.map(flow => ({
            ...flow,
            registered: registeredFlowIds.has(flow.id)
          }));
          setFlows(updatedFlows);
        }
      } else {
        setFlows(flowsData);
      }
    }
    setLoading(false);
  }, [session]);

  const fetchCategories = useCallback(async () => {
    const { data: categoriesData, error: categoriesError } = await fetchCategoriesData();

    if (categoriesError) {
      console.error('Erreur de récupération des catégories:', categoriesError.message);
    } else {
      setCategories(categoriesData);
    }
  }, []);

  const fetchCountries = useCallback(async () => {
    const { data: flowsData, error: flowsError } = await fetchCountriesData();

    if (flowsError) {
      console.error('Erreur de récupération des pays:', flowsError.message);
    } else {
      const uniqueCountries = Array.from(new Set(flowsData.map(flow => flow.country)));
      setCountries(uniqueCountries);
    }
  }, []);

  const filterFlows = useCallback((term, language, category, country) => {
    const filtered = flows.filter(flow => {
      const flowNameLower = flow.flow_name.toLowerCase();
      const flowLanguageLower = flow.language.toLowerCase();
      const flowCountryLower = flow.country.toLowerCase();
      const flowDescriptionLower = flow.flow_description.toLowerCase();
      const termLower = term.toLowerCase();

      const matchesTerm = flowNameLower.includes(termLower) ||
                          flowLanguageLower.includes(termLower) ||
                          flowCountryLower.includes(termLower) ||
                          flowDescriptionLower.includes(termLower);

      const matchesLanguage = language === 'All' || flowLanguageLower === language.toLowerCase();
      const matchesCategory = category === 'All' || flow.categorie_flux_id === parseInt(category, 10);
      const matchesCountry = country === 'All' || flowCountryLower === country.toLowerCase();

      return matchesTerm && matchesLanguage && matchesCategory && matchesCountry;
    });

    setFilteredFlows(filtered);
  }, [flows]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleRegister = (flow) => {
    setSelectedFlow(flow);
    if (flow.modalNumber !== null) {
      setShowModal(true);
    } else {
      setShowConfirmation(true);
    }
  };

  const handleModalSubmit = (formData) => {
    const fullUrl = selectedFlow.flow_url.replace('{category}', formData.category || '').replace('{city}', formData.city || '');
    setFormData({ url: fullUrl });
    setShowModal(false);
    handleConfirmRegistration(fullUrl);
  };

  const handleModalFlightSubmit = (formData) => {
    const fullUrl = JSON.stringify(formData)
    setFormData({ url: fullUrl });
    setShowModal(false);
    handleConfirmRegistration(fullUrl);
  };

  const handleConfirmRegistration = async (url) => {
    if (session && selectedFlow) {
      const { error } = await insertInscription(session.user.id, selectedFlow.id, url);

      if (error) {
        console.error('Erreur d\'inscription au flux:', error.message);
      } else {
        setShowModal(false);
        setShowConfirmation(false);
        await fetchFlows();
      }
    } else {
      console.warn('Session ou flux sélectionné non défini.');
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setShowConfirmation(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchFlows();
      await fetchCategories();
      await fetchCountries();
    };

    fetchData();
  }, [fetchFlows, fetchCategories, fetchCountries]);

  useEffect(() => {
    filterFlows(searchTerm, selectedLanguage, selectedCategory, selectedCountry);
  }, [flows, searchTerm, selectedLanguage, selectedCategory, selectedCountry, filterFlows]);

  useEffect(() => {
    // Ajuster la hauteur de la navbar dans les variables CSS
    const navbarHeight = document.querySelector('.navbar')?.offsetHeight || 0;
    document.documentElement.style.setProperty('--navbar-height', `${navbarHeight}px`);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flows-container">
      <nav className="navbar">
        {/* Contenu de la navbar */}
      </nav>

      <h1>Liste de Newsletters</h1>
      <input
        type="text"
        placeholder="Rechercher par mot clé..."
        value={searchTerm}
        onChange={handleSearch}
        className="search-input"
      />
      <select
        value={selectedLanguage}
        onChange={handleLanguageChange}
        className="language-select"
      >
        <option value="All">Toutes les langues</option>
        {Array.from(new Set(flows.map(flow => flow.language))).map(language => (
          <option key={language} value={language}>{language}</option>
        ))}
      </select>
      <select
        value={selectedCategory}
        onChange={handleCategoryChange}
        className="category-select"
      >
        <option value="All">Toutes les catégories</option>
        {categories.map(category => (
          <option key={category.categorie_flux_id} value={category.categorie_flux_id}>{category.category_name}</option>
        ))}
      </select>

      <select
        value={selectedCountry}
        onChange={handleCountryChange}
        className="country-select"
      >
        <option value="All">Tous les pays</option>
        {countries.map(country => (
          <option key={country} value={country}>{country}</option>
        ))}
      </select>
      <div className="flows-list">
        {filteredFlows.map((flow) => (
          <div key={flow.id} className={`flow-item ${flow.registered ? 'registered' : ''}`}>
            <img src={flow.flow_logo} alt={flow.flow_name} className="flow-logo" />
            <div className="flow-details">
              <h2>{flow.flow_name}</h2>
              <p><strong>Langue:</strong> {flow.language}</p>
              <p><strong>Pays:</strong> {flow.country}</p>
              <p><strong>Fréquence de publication:</strong> {flow.publication_frequency}</p>
              <p><strong>Description:</strong> {flow.flow_description}</p>
            </div>
            {flow.registered ? (
              <button className="btn-disabled">Vous êtes déjà inscrit à ce flux</button>
            ) : (
              <button onClick={() => handleRegister(flow)}>S'inscrire</button>
            )}
          </div>
        ))}
      </div>

      {showModal && selectedFlow && selectedFlow.modalNumber === 1 && (
        <div className="modal">
          <div className="modal-content">
            <EventbriteApiModal onSubmit={handleModalSubmit} onCancel={handleCancel} />
          </div>
        </div>
      )}

      {showModal && selectedFlow && selectedFlow.modalNumber === 2 && (
        <div className="modal">
          <div className="modal-content">
            <button className="modal-close btn btn-danger" onClick={handleCancel}>&times;</button>
            <FlightForm onSubmit={handleModalFlightSubmit} onCancel={handleCancel} />
          </div>
        </div>
      )}
      
      {showConfirmation && (
        <div className="modal">
          <div className="modal-content">
            <p>Êtes-vous sûr de vouloir vous inscrire à ce flux?</p>
            <button className="btn btn-primary" onClick={() => handleConfirmRegistration(formData.url)}>Confirmer</button>
            <button className="btn btn-warning" onClick={handleCancel}>Annuler</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Flux;
