import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import airportsData from 'airport-codes/airports.json'; // Assurez-vous que le chemin est correct
import './FlightForm.css'; // Assurez-vous d'importer votre fichier CSS

const FlightForm = ({ onSubmit, onCancel }) => {
    const [depart, setDepart] = useState(null); // État pour l'aéroport de départ
    const [arrivee, setArrivee] = useState(null); // État pour l'aéroport d'arrivée
    const [dateDepart, setDateDepart] = useState(''); // État pour la date de départ
    const [dateRetour, setDateRetour] = useState(''); // État pour la date de retour
    const [nbAdultes, setNbAdultes] = useState(1); // État pour le nombre d'adultes
    const [nbEnfants, setNbEnfants] = useState(0); // État pour le nombre d'enfants
    const [typeVoyage, setTypeVoyage] = useState('aller-simple'); // État pour le type de voyage
    const [classeCabine, setClasseCabine] = useState('economy'); // État pour la classe de cabine
    const [prixMax, setPrixMax] = useState(''); // État pour le prix maximum
    const [errorMessage, setErrorMessage] = useState(''); // État pour les messages d'erreur

    // Filtrer les aéroports selon l'entrée utilisateur
    const filterAirports = (inputValue) => {
        return airportsData.filter(airport =>
            airport.name.toLowerCase().includes(inputValue.toLowerCase()) ||
            airport.city.toLowerCase().includes(inputValue.toLowerCase())
        ).map(airport => ({
            label: `${airport.name} (${airport.iata})`,
            value: airport.iata,
            code: airport.iata,
        }));
    };

    // Fonction asynchrone pour récupérer les options d'aéroport
    const fetchAirports = async (inputValue) => {
        if (!inputValue) return [];
        return filterAirports(inputValue);
    };

    // Gestion du changement pour l'aéroport de départ
    const handleDepartChange = (selectedOption) => setDepart(selectedOption);

    // Gestion du changement pour l'aéroport d'arrivée
    const handleArriveeChange = (selectedOption) => setArrivee(selectedOption);

    // Gestion du changement pour le type de voyage
    const handleTypeVoyageChange = (e) => {
        const selectedType = e.target.value;
        setTypeVoyage(selectedType);
        if (selectedType !== 'aller-retour') setDateRetour(''); // Réinitialise la date de retour si le type n'est pas aller-retour
    };

    // Fonction pour gérer la soumission du formulaire
    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Réinitialiser le message d'erreur
        setErrorMessage('');

        // Validation des dates
        const currentDate = new Date(); // Date actuelle
        const departureDate = new Date(dateDepart); // Convertir la date de départ en objet Date
        const returnDate = new Date(dateRetour); // Convertir la date de retour en objet Date

        // Vérification des aéroports sélectionnés
        if (!depart || !arrivee) {
            setErrorMessage('Veuillez sélectionner à la fois les aéroports de départ et d\'arrivée.');
            return;
        }

        // Vérification que la date de départ n'est pas dans le passé
        if (departureDate < currentDate) {
            setErrorMessage('La date de départ ne peut pas être dans le passé.');
            return;
        }

        // Vérification que la date de retour est supérieure à la date de départ
        if (typeVoyage === 'aller-retour' && returnDate <= departureDate) {
            setErrorMessage('La date de retour doit être supérieure à la date de départ.');
            return;
        }

        // Création de l'objet de données du formulaire
        const formData = {
            depart: depart?.code || '',
            arrivee: arrivee?.code || '',
            dateDepart: dateDepart.replace(/-/g, ''), // Formatage de la date de départ
            dateRetour: dateRetour ? dateRetour.replace(/-/g, '') : '', // Formatage de la date de retour
            nbAdultes,
            nbEnfants,
            typeVoyage,
            classeCabine: {
                economy: 'e',
                premium_economy: 'p',
                business: 'b',
                first: 'f',
            }[classeCabine],
            prixMax,
        };

        // Appel de la fonction onSubmit si elle est définie
        if (onSubmit) onSubmit(formData);
    };

    return (
        <div className="flight-form-container"> {/* Classe parent */}
            <h1>Paramètres de Vols</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Type de voyage:</label>
                    <div className="form-group">
                        <label htmlFor="typeVoyage">Type de voyage:</label>
                        <select
                            id="typeVoyage"
                            value={typeVoyage}
                            onChange={handleTypeVoyageChange}
                            required
                        >
                            <option value="aller-simple">Aller simple</option>
                            <option value="aller-retour">Aller-retour</option>
                        </select>
                    </div>

                </div>

                <div className="form-group">
                    <label htmlFor="depart">Départ (ville):</label>
                    <AsyncSelect
                        id="depart"
                        value={depart}
                        onChange={handleDepartChange}
                        loadOptions={fetchAirports}
                        placeholder="Entrez la ville ou l'aéroport"
                        aria-label="Select departure city or airport"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="arrivee">Arrivée (ville):</label>
                    <AsyncSelect
                        id="arrivee"
                        value={arrivee}
                        onChange={handleArriveeChange}
                        loadOptions={fetchAirports}
                        placeholder="Entrez la ville ou l'aéroport"
                        aria-label="Select arrival city or airport"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="dateDepart">Date de départ:</label>
                    <input
                        type="date"
                        id="dateDepart"
                        value={dateDepart}
                        onChange={(e) => setDateDepart(e.target.value)}
                        required
                    />
                </div>

                {typeVoyage === 'aller-retour' && (
                    <div className="form-group">
                        <label htmlFor="dateRetour">Date de retour:</label>
                        <input
                            type="date"
                            id="dateRetour"
                            value={dateRetour}
                            onChange={(e) => setDateRetour(e.target.value)}
                            required
                        />
                    </div>
                )}

                <div className="form-group">
                    <label htmlFor="nbAdultes">Nombre de passagers adultes:</label>
                    <input
                        type="number"
                        id="nbAdultes"
                        value={nbAdultes}
                        onChange={(e) => setNbAdultes(e.target.value)}
                        min="1"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="nbEnfants">Nombre de passagers mineurs:</label>
                    <input
                        type="number"
                        id="nbEnfants"
                        value={nbEnfants}
                        onChange={(e) => setNbEnfants(e.target.value)}
                        min="0"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="prixMax">Prix maximum (€):</label>
                    <input
                        type="number"
                        id="prixMax"
                        value={prixMax}
                        onChange={(e) => setPrixMax(e.target.value)}
                        min="0"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="classeCabine">Classe de cabine:</label>
                    <select
                        id="classeCabine"
                        value={classeCabine}
                        onChange={(e) => setClasseCabine(e.target.value)}
                        required
                    >
                        <option value="economy">Économie</option>
                        <option value="premium_economy">Économie Premium</option>
                        <option value="business">Affaires</option>
                        <option value="first">Première Classe</option>
                    </select>
                </div>

                {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Affichage du message d'erreur */}

                <div className="modal-buttons">
                    <button type="submit" className="btn btn-primary">Confirmer</button>
                    <button type="button" className="btn btn-warning" onClick={onCancel}>Annuler</button>
                </div>
            </form>
        </div>
    );
};

export default FlightForm;
