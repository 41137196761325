import React, { useState } from 'react';
import validator from 'validator';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import axios from 'axios';
import './Contact.css'; // Assurez-vous que ce fichier CSS existe

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    helpTopic: 'Presse et Partenariat',
    description: '',
    attachment: null,
  });

  const [errors, setErrors] = useState({
    phone: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      attachment: e.target.files[0],
    });
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Retirer le préfixe base64
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    // Validation du numéro de téléphone
    const phoneNumber = parsePhoneNumberFromString(formData.phone, 'FR'); // Utilisez le code de pays approprié
    if (!phoneNumber || !phoneNumber.isValid()) {
      setErrors((prevErrors) => ({ ...prevErrors, phone: 'Numéro de téléphone invalide.' }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, phone: '' }));
    }

    // Validation de l'adresse e-mail
    if (!validator.isEmail(formData.email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Adresse e-mail invalide.' }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
    }

    if (hasErrors) return;

    try {
      // Convertir la pièce jointe en base64
      const attachmentBase64 = formData.attachment
        ? await convertFileToBase64(formData.attachment)
        : '';

      // Préparer les données pour l'envoi
      const data = {
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        helpTopic: formData.helpTopic,
        description: formData.description,
        attachment: attachmentBase64,
      };

      // Envoyer les données à l'URL HTTP de Power Automate
      await axios.post(
        'https://prod-94.westeurope.logic.azure.com:443/workflows/5006989ec2ac476ca47ecdb20251508e/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=3p5STk8ZBflhmFMNB_8hICH_NkejxKvz6LhPnaFrvyQ',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      alert('Votre demande a été envoyée avec succès.');
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la demande:', error.response ? error.response.data : error.message);
      alert('Une erreur est survenue. Veuillez réessayer.');
    }

    // Réinitialiser le formulaire après soumission
    setFormData({
      name: '',
      phone: '',
      email: '',
      helpTopic: 'Presse et Partenariat',
      description: '',
      attachment: null,
    });
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Formulaire de Contact</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nom:</label>
          <input
            type="text"
            id="name"
            name="name"
            className="form-control"
            value={formData.name}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone">Numéro de téléphone <span className="required">*</span>:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="form-control"
            value={formData.phone}
            onChange={handleChange}
            required
            pattern="^\+?[1-9]\d{1,14}$"
            title="Entrez un numéro de téléphone valide (ex: +1234567890)"
          />
          {errors.phone && <small className="form-text text-danger">{errors.phone}</small>}
          <small className="form-text">Entrez votre numéro de téléphone au format international.</small>
        </div>

        <div className="form-group">
          <label htmlFor="email">Adresse mail <span className="required">*</span>:</label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && <small className="form-text text-danger">{errors.email}</small>}
          <small className="form-text">Entrez une adresse e-mail valide.</small>
        </div>

        <div className="form-group">
          <label htmlFor="helpTopic">Comment pouvons-nous vous aider ?</label>
          <select
            id="helpTopic"
            name="helpTopic"
            className="form-control"
            value={formData.helpTopic}
            onChange={handleChange}
            required
          >
            <option value="Presse et Partenariat">Presse et Partenariat</option>
            <option value="Consultant IT">Consultant IT</option>
            <option value="Paiement, Prix et Remboursement">Paiement, Prix et Remboursement</option>
            <option value="Les Avis">Les Avis</option>
            <option value="Suggestion et Améliorations">Suggestion et Améliorations</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="description">Description <span className="required">*</span>:</label>
          <textarea
            id="description"
            name="description"
            className="form-control"
            value={formData.description}
            onChange={handleChange}
            rows="5"
            required
          />
          <small className="form-text">Fournissez des détails sur votre demande.</small>
        </div>

        <div className="form-group">
          <label htmlFor="attachment">Ajouter une pièce jointe:</label>
          <input
            type="file"
            id="attachment"
            name="attachment"
            className="form-control-file"
            onChange={handleFileChange}
          />
        </div>

        <button type="submit" className="btn btn-primary">Envoyer</button>
      </form>
    </div>
  );
};

export default Contact;
