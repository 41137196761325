// src/components/Auth.js
import React from 'react';
import { useAuth } from '../context/AuthContext';
import supabase from '../config/supabaseClient';

const Auth = () => {
  const { setSession } = useAuth();

  const signInWithGoogle = async () => {
    const { error } = await supabase.auth.signIn({ provider: 'google' });
    if (error) {
      console.error('Erreur d\'authentification:', error.message);
    }
  };

  return (
    <div>
      <h1>Connexion</h1>
      <button onClick={signInWithGoogle}>Se connecter avec Google</button>
    </div>
  );
};

export default Auth;
