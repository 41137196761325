import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../config/supabaseClient';
import './Home.css';

function Home() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUser = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session?.user) {
        await saveUserToDatabase(session.user);
        navigate('/flux');
      }
      setLoading(false);
    };

    checkUser();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (_, session) => {
      if (session?.user) {
        await saveUserToDatabase(session.user);
        navigate('/flux');
      }
      setLoading(false);
    });

    return () => subscription?.unsubscribe();
  }, [navigate]);

  const saveUserToDatabase = async (user) => {
    const { error } = await supabase
      .from('users')
      .upsert({
        id: user.id,
        email: user.email,
        username: user.username,
      })
      .single();

    if (error) {
      console.error('Erreur lors de l\'enregistrement dans la base de données:', error.message);
    } else {
      console.log('Utilisateur enregistré avec succès.');
    }
  };

  const handleLogin = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      });

      if (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      console.error('Erreur lors de la connexion:', error.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container my-5">
      <div className="company-overview text-center">
        <h1>Bienvenue chez Tetrisnews S.A.S</h1>
        <p>Tetrisnews S.A.S est une entreprise spécialisée dans le conseil en IT, la création de solutions applicatives, et l'automatisation des processus métiers. Nous aidons les entreprises à optimiser leurs opérations grâce à des solutions innovantes et sur mesure.</p>
        <p>En plus de nos services de conseil, nous offrons une plateforme de newsletters personnalisées qui permet au public de recevoir des informations en fonction de leurs centres d'intérêt. Notre plateforme est en constante évolution pour permettre à chacun de trouver une newsletter qui lui correspond parfaitement.</p>
      </div>
      
      <div className="col-lg-6 mx-auto">
        <div className="card text-center border-primary">
          <div className="card-header bg-transparent border-0">
          

            <h5 className="card-title">Nos services gratuit de Newsletters</h5>
          </div>
          <div><i className="bi bi-envelope"></i></div>
          <div className="card-body">
            <p className="card-price">Recevez gratuitement des flux d'informations selon vos préférences.</p>
            <ul className="list-unstyled">
              <li className="card-feature">Nous avons tous des préférences en matière d'informations. Il est souvent difficile de se repérer dans le flux d'informations qui nous submerge au quotidien. C'est pourquoi nous avons conçu notre plateforme de newsletters, pour permettre à chacun de recevoir uniquement les informations qui l'intéressent.</li>
            </ul>
            <button onClick={handleLogin} className="btn btn-dark">Se connecter</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
