import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Importation du fichier CSS

function Footer() {
  return (
    <div className="footerContainer">
      <div className="socialIcons">
        <a href="https://www.linkedin.com/groups/14445246/" target="_blank" rel="noopener noreferrer">
          <i className="bi bi-linkedin"></i>
        </a>
        <a href="https://www.tiktok.com/@tetrisnews" target="_blank" rel="noopener noreferrer">
          <i className="bi bi-tiktok"></i>
        </a>
      </div>
      <div className="footerNav">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/contact">Contact Us</Link></li>
          <li><Link to="/legalprivacy">LegalPrivacy</Link></li>
          <li><Link to="/termofservice"> TermofService</Link></li>
        </ul>
      </div>
      <div className="footerBottom">
        <p>Copyright &copy; 2024; Designed by <span className="designer">Thibaut Talom</span></p>
      </div>
    </div>
  );
}

export default Footer;
