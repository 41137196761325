import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import supabase from '../config/supabaseClient';
import './Flux.css'; // Assurez-vous que ce fichier CSS existe

const MesFlux = () => {
  const { session } = useAuth();
  const [myFlows, setMyFlows] = useState([]);
  const [filteredFlows, setFilteredFlows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchMyFlows = async () => {
      if (session) {
        const { data: inscriptions, error: insError } = await supabase
          .from('inscription')
          .select('flow_id')
          .eq('user_id', session.user.id);

        if (insError) {
          console.error('Erreur de récupération des inscriptions:', insError.message);
          setLoading(false);
          return;
        }

        const flowIds = inscriptions.map(inscription => inscription.flow_id);

        const { data: flowsData, error: flowsError } = await supabase
          .from('flux')
          .select('id, flow_name, language, country, publication_frequency, flow_description, flow_logo')
          .in('id', flowIds);

        if (flowsError) {
          console.error('Erreur de récupération des flux:', flowsError.message);
        } else {
          setMyFlows(flowsData);
          setFilteredFlows(flowsData);
        }
      }
      setLoading(false);
    };

    fetchMyFlows();
  }, [session]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    filterFlows(value);
  };

  const filterFlows = (term) => {
    const filtered = myFlows.filter(flow => {
      return flow.flow_name.toLowerCase().includes(term) ||
             flow.language.toLowerCase().includes(term) ||
             flow.country.toLowerCase().includes(term) ||
             flow.flow_description.toLowerCase().includes(term);
    });
    setFilteredFlows(filtered);
  };

  const handleUnsubscribe = async (flowId) => {
    if (session) {
      const { error } = await supabase
        .from('inscription')
        .delete()
        .eq('user_id', session.user.id)
        .eq('flow_id', flowId);

      if (error) {
        console.error('Erreur de désinscription:', error.message);
      } else {
        // Exclure le flux désabonné de la liste filtrée
        setFilteredFlows(prevFlows =>
          prevFlows.filter(flow => flow.id !== flowId)
        );
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="my-flows-container">
      <h1>Mes Newsletters</h1>
      <input
        type="text"
        placeholder="Rechercher parmi mes flux..."
        value={searchTerm}
        onChange={handleSearch}
        className="search-input"
      />
      <div className="flows-list">
        {filteredFlows.length > 0 ? (
          filteredFlows.map((flow) => (
            <div key={flow.id} className="flow-item">
              <img src={flow.flow_logo} alt={flow.flow_name} className="flow-logo" />
              <div className="flow-details">
                <h2>{flow.flow_name}</h2>
                <p><strong>Langue:</strong> {flow.language}</p>
                <p><strong>Pays:</strong> {flow.country}</p>
                <p><strong>Fréquence de publication:</strong> {flow.publication_frequency}</p>
                <p><strong>Description:</strong> {flow.flow_description}</p>
              </div>
              <button onClick={() => handleUnsubscribe(flow.id)}>Se désabonner</button>
            </div>
          ))
        ) : (
          <p>Aucun flux inscrit.</p>
        )}
      </div>
    </div>
  );
};

export default MesFlux;
