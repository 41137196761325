// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext'; // Assurez-vous que le chemin est correct
import Home from './pages/Home';
import LegalPrivacy from './pages/LegalPrivacy';
import Flux from './pages/Flux';
import Contact from './pages/Contact';
import MesFlux from './pages/MesFlux';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import TermsofService from './pages/TermofService';
import Profile from './pages/Profile'; // Assurez-vous que ce fichier existe
import ProtectedRoute from './components/ProtectedRoute'; // Assurez-vous que le chemin est correct
import Auth from './components/Auth';
import FlightForm from './components/FlightForm';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Routes>
        <Route path="/auth" component={Auth} />
          <Route path="/" element={<Home />} />
          <Route path="/flux" element={
            <ProtectedRoute>
              <Flux />
            </ProtectedRoute>
          }/>
          <Route path="/legalprivacy" element={<LegalPrivacy />} />
          <Route path="/termofservice" element={<TermsofService />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/profile" element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }/>
          <Route path="/flight" element={
            <ProtectedRoute>
              <FlightForm />
            </ProtectedRoute>
          }/>
          <Route path="/mesflux" element={
            <ProtectedRoute>
              <MesFlux />
            </ProtectedRoute>
          }/>
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
